<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path
      class="st0"
      d="M210.349,85.588c0-47.119-38.22-85.338-85.358-85.338c-47.12,0-85.339,38.219-85.339,85.338
	c-12.188,1.529-23.706,9.486-23.706,27.979v44.584c0,20.859,14.597,28.44,28.439,28.44h13.759c2.614,0,4.733-2.119,4.733-4.733
	V90.049c0-2.614-2.119-4.733-4.733-4.733h-9.005c0-41.904,33.966-75.871,75.852-75.871c41.904,0,75.87,33.967,75.87,75.871h-9.002
	c-2.615,0-4.754,2.139-4.754,4.754v91.641c0,2.614,2.119,4.733,4.733,4.733h9.023c-1.318,32.584-16.982,40.208-48.227,41.589v-3.182
	c0-5.24-4.248-9.488-9.488-9.488h-22.103c-9.49,0-17.183,7.693-17.183,17.183l0,0c0,9.501,7.702,17.203,17.203,17.203h22.189
	c5.239,0,9.486-4.247,9.486-9.486v-2.744c30.049-1.382,56.039-7.58,57.61-51.349c12.188-1.529,23.706-9.486,23.706-27.979V113.63
	C234.055,95.033,222.579,87.117,210.349,85.588z"
    />
  </icon-base>
</template>

<script>
export default {
  name: 'iconHeadset',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>
